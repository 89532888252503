import '@/styles/globals.css'
import Head from "next/head";
import {Suspense} from "react";

export default function App({ Component, pageProps }) {
  return <>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    </Head>
    {/*<Suspense fallback={<p>Loading</p>}>*/}
      <Component {...pageProps} />
    {/*</Suspense>*/}
  </>
}
